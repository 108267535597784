import Utilities from "./_utilities";
import PartsInclude from "./_partsInclude";
import CommonUI from "./_ui";
import Backhead from "./_backhead";
import AnchorLink from "./_anchorlink";
import ScrollDownOnFirstView from "./_scroll-down-on-firstview";
const partsInclude = new PartsInclude;
// format-detection 追加
document.head.insertAdjacentHTML('beforeend', '<meta name="format-detection" content="telephone=no">');
// JSインクルード完了後（include-completeイベント発火後）に機能をアクティブ
document.addEventListener('include-complete', () => {
    const commonUI = new CommonUI;
    const backhead = new Backhead;
    const anchorlink = new AnchorLink;
    const scrollDownOnFirstView = new ScrollDownOnFirstView;
});
// iOSのbackground-attachementバグ対策
if (Utilities.deviceInspector().isIOS || Utilities.deviceInspector().isIPadOS) {
    document.querySelector('html').classList.add('is-iOS');
}
