import BackgroundScrollLock from "./_background-scroll-lock";
/* UI制御 */
export default class CommonUI {
    constructor() {
        this.scrollLock = BackgroundScrollLock.getInstance();
        this.mainHeader = document.querySelector('.l-mainheader'); // メインヘッダー
        this.navToggle = document.querySelector('.l-mainheader__navtoggle'); // ナビトグルボタン
        this.navWrapper = document.querySelector('.l-mainnav'); // メインナビ
        if (this.mainHeader && this.navWrapper) {
            this.navSub = document.querySelectorAll('.l-mainnav__sub'); // サブナビ
            this.navHasSub = this.navWrapper.querySelectorAll('.has-subnav'); // 開閉リンク
            this.mainFunc = {
                // メインナビ制御
                navToggleHandler: (ev) => {
                    ev.preventDefault();
                    this.mainHeader.classList.toggle('sp-nav-active');
                    if (this.mainHeader.classList.contains('sp-nav-active')) {
                        this.scrollLock.lock();
                    }
                    else {
                        this.scrollLock.unlock();
                    }
                },
                listToggle: (ev) => {
                    const target = ev.target;
                    ev.preventDefault();
                    target.parentElement.classList.toggle('sp-subnav-active');
                },
                megaMenuOpen: (ev) => {
                    const target = ev.target;
                    ev.preventDefault();
                    this.mainFunc.megaMenuClose();
                    target.parentElement.classList.add('pc-subnav-active');
                },
                megaMenuClose: (ev) => {
                    this.navHasSub.forEach((elm) => {
                        elm.parentElement.classList.remove('pc-subnav-active');
                    });
                }
            };
            this.init();
        }
    }
    init() {
        let resizeTimer; // リサイズ遅延タイマー
        let hoverTimer; // マウスホバー遅延タイマー
        this.destroy(); // reset
        if (window.innerWidth < 1058) {
            // SPナビ
            this.navToggle.addEventListener('click', this.mainFunc.navToggleHandler); // メインナビ
            this.navHasSub.forEach(elm => {
                elm.addEventListener('click', this.mainFunc.listToggle); // サブナビ開閉
            });
        }
        else {
            // PCナビ
            this.navSub.forEach(elm => {
                // サブナビメガメニュー開く（即時）
                elm.addEventListener('mouseenter', (ev) => {
                    clearTimeout(hoverTimer);
                    this.mainFunc.megaMenuOpen(ev);
                });
                // サブナビメガメニュー閉じる（即時）
                elm.addEventListener('mouseleave', () => {
                    this.mainFunc.megaMenuClose();
                });
            });
            this.navHasSub.forEach(elm => {
                // サブナビメガメニュー開く（遅延付き）
                elm.addEventListener('mouseenter', (ev) => {
                    clearTimeout(hoverTimer);
                    hoverTimer = setTimeout(() => {
                        this.mainFunc.megaMenuOpen(ev);
                    }, 200);
                });
                // サブナビメガメニュー閉じる（遅延付き）
                elm.addEventListener('mouseleave', () => {
                    clearTimeout(hoverTimer);
                    hoverTimer = setTimeout(this.mainFunc.megaMenuClose, 200);
                });
            });
        }
        window.addEventListener('resize', () => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                this.init();
            }, 200);
        });
    }
    destroy() {
        this.navToggle.removeEventListener('click', this.mainFunc.navToggleHandler); // メインナビ
        this.navHasSub.forEach(elm => {
            elm.removeEventListener('click', this.mainFunc.listToggle); // サブナビ開閉
            elm.removeEventListener('mouseenter', this.mainFunc.megaMenuOpen); // サブナビメガメニュー開く
            elm.parentElement.classList.remove('sp-subnav-active');
            elm.parentElement.classList.remove('pc-subnav-active');
        });
        this.navSub.forEach(elm => {
            elm.removeEventListener('mouseleave', this.mainFunc.megaMenuClose); // サブナビメガメニュー閉じる
        });
        this.mainHeader.classList.remove('sp-nav-active');
        this.scrollLock.unlock();
    }
}
