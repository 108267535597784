/* ファーストビューのスクロールボタン */
export default class ScrollDownOnFirstView {
    constructor() {
        this.button = document.querySelector('.js-scroll-down'); //as HTMLElement;
        if (this.button) {
            this.init();
        }
    }
    init() {
        this.toggle();
        window.addEventListener("scroll", () => {
            this.toggle();
        });
        this.button.addEventListener('click', (ev) => {
            ev.preventDefault();
            const firstViewHeight = window.innerHeight;
            window.scrollTo({
                top: firstViewHeight,
                left: 0,
                behavior: 'smooth'
            });
        });
    }
    toggle() {
        if (window.scrollY === 0) {
            this.button.classList.add('active');
            return;
        }
        this.button.classList.remove('active');
    }
}
