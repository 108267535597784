/* ユーティリティ関数 */
export default class Utilities {
    constructor() {
    }
    // タッチデバイス判定
    static isTouchDevice() {
        return window.ontouchstart || navigator.maxTouchPoints;
    }
    // デバイス判定（必要に応じて追加）
    static deviceInspector() {
        const UA = navigator.userAgent;
        const deviceObj = {
            isIOS: false,
            isIPadOS: false
        };
        (/iPhone/.test(UA)) ? deviceObj.isIOS = true : deviceObj.isIOS = false;
        (/iPad/.test(UA)) ? deviceObj.isIPadOS = true : deviceObj.isIPadOS = false;
        return deviceObj;
    }
}
