/* 背景スクロールロック */
export default class BackgroundScrollLock {
    constructor() {
        this.html = document.querySelector('html');
    }
    static getInstance() {
        if (!this.backgroundScrollLock) {
            this.backgroundScrollLock = new BackgroundScrollLock;
        }
        return this.backgroundScrollLock;
    }
    lock() {
        if (!this.html.classList.contains('locked')) {
            this.html.classList.add('locked');
        }
    }
    unlock() {
        if (this.html.classList.contains('locked')) {
            this.html.classList.remove('locked');
        }
    }
}
