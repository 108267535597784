/* JSインクルード */
export default class PartsInclude {
    constructor() {
        this.mainHeader = document.getElementById('mainHeader'); // メインヘッダー
        this.mainFooter = document.getElementById('mainFooter'); // メインフッター
        this.leadingLink = document.getElementById('leadingLink'); // 誘導リンク
        this.localNav = document.getElementById('localNav'); // ローカルナビ
        this.isLeadingLink = (this.leadingLink) ? true : false;
        this.isLocalNav = (this.localNav) ? true : false;
        this.localNavURL = ''; // ローカルナビURL
        if (this.isLocalNav) {
            this.localNavURL = '/assets/includes/localnav-' + this.localNav.dataset.localnav + '.html';
        }
        this.complete = new Event('include-complete'); // インクルード完了カスタムイベント
        this.init();
    }
    init() {
        const _this = this;
        let fetchList = [
            fetch('/assets/includes/main-header.html').then((response) => { return response.text(); }),
            fetch('/assets/includes/main-footer.html').then((response) => { return response.text(); })
        ];
        if (_this.isLocalNav) {
            fetchList.push(fetch('/assets/includes/leadinglink.html').then((response) => { return response.text(); }));
        }
        if (_this.isLocalNav) {
            fetchList.push(fetch(_this.localNavURL).then((response) => { return response.text(); }));
        }
        Promise.all(fetchList).then((data) => {
            if (_this.mainHeader) {
                _this.mainHeader.innerHTML = data[0];
            }
            if (_this.mainFooter) {
                _this.mainFooter.innerHTML = data[1];
            }
            // 誘導リンクがあれば追加
            if (_this.isLeadingLink) {
                _this.leadingLink.innerHTML = data[2];
            }
            // ローカルナビがあれば追加
            if (_this.isLocalNav) {
                _this.localNav.innerHTML = data[3];
            }
            // インクルード完了カスタムイベントを発火
            document.dispatchEvent(_this.complete);
        })
            .catch(error => {
            console.error('Error:', error);
        });
    }
}
